@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/scss/variables.scss';

@font-face {
  font-family: gothamMedium;
  src: url(assets/fonts/gotham/GothamMedium.ttf);
}

@font-face {
  font-family: gothamBold;
  src: url(assets/fonts/gotham/GothamBold.ttf);
}

@font-face {
  font-family: gothamBook;
  src: url(assets/fonts/gotham/GothamBook.ttf);
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-VariableFont.ttf) format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

:root {
  --first-color: #4bbdcb;
  --container-color: #ffffff;
  --background-color: rgb(239, 243, 248);
  --panel-left-color: rgb(255, 255, 255);
  --profile-color: rgb(255, 255, 255);
  --color-primary: #4bbdcb;
  --color-primary-hover: #45aab8;
  --color-primary-dark: rgb(63, 158, 170);
  --color-primary-light: rgb(75, 189, 203, 0.04);
  --text-color: #707070;
  --normal-font-size: 16px;

  --body-font: 'Inter', ui-sans-serif, system-ui, -apple-system, 'Roboto', 'Arial', 'Poppins', sans-serif;
}

.p-card-content {
  padding: 0px !important;
}

.principal-card {
  border-radius: 10px;
}

html,
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color);
  scroll-behavior: smooth;
}

.bd-container {
  max-width: 1200px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-subtitle {
  text-align: center;
}

.section-title {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-3);
}

.section-subtitle {
  display: block;
  color: var(--first-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
  font-size: 25px;
}

.contenedor {
  width: 100%;
  max-width: 1366px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.divider {
  margin: 3rem 2rem;
  background-color: #ddd;
  height: 1px;
  width: 100%;
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.icon-pointer {
  cursor: pointer;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

app-input-set[type='text'],
app-input-set[type='email'],
app-input-set[type='url'],
app-input-set[type='password'],
app-input-set[type='number'],
app-input-set[type='date'],
app-input-set[type='datetime-local'],
app-input-set[type='month'],
app-input-set[type='search'],
app-input-set[type='tel'],
app-input-set[type='time'],
app-input-set[type='week'],
app-input-set[type='textarea'],
app-input-set[type='select'] {
  padding: 0;
  border: 0;
  background: none;
}
